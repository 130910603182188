import React from 'react';
import loadable from '@loadable/component';
import config from '../../config';
import Preloader from '../../components/Preloader';

const NotFoundPage = loadable(
  () => import(
    /* webpackChunkName: "not-found-page-chunk" */
    './NotFoundPage'
  ),
  {
    fallback: (
      <Preloader isDefault={config.isLogisticForce || config.isTerborg} />
    ),
  }
);

export default NotFoundPage;
