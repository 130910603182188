import React from 'react';
import loadable from '@loadable/component';
import config from '../../config';
import Preloader from '../../components/Preloader';

const GigImportPage = loadable(
  () => import(
    /* webpackChunkName: "shift-import-page-chunk" */
    './GigImportPage'
  ),
  {
    fallback: (
      <Preloader isDefault={config.isLogisticForce || config.isTerborg} />
    ),
  }
);

export default GigImportPage;
